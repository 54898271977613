<template>
    <div>
        <ValidationObserver ref="form1" slim>
            <form @submit.prevent="register()">
                <div class="text-center mb-4">
                    <h1 class="fs-light">Registeren</h1>
                    <div class="text-muted">
                        Heb je al een Tixgo-account? <router-link :to="login_to">Inloggen</router-link>
                    </div>
                </div>
                
                <b-alert variant="danger" show v-if="error">
                    {{ error }}
                </b-alert>

                <b-row>      
                    <b-col cols="12" sm="6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Voornaam</label>
                                <b-form-input v-model="form.firstname" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Achternaam</label>
                                <b-form-input v-model="form.lastname" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">E-mail</label>
                        <b-form-input v-model="form.email" placeholder="mijn@account.nl" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <ValidationProvider rules="required|min:8" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Wachtwoord</label>
                        <b-form-input type="password" v-model="form.password" placeholder="●●●●●●●●●" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            
                <b-row>
                    <b-col cols="12" sm="6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Land</label>
                                <b-select :options="countryOptions" v-model="form.country" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>

                    <b-col cols="12" sm="6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Postcode</label>
                                <b-form-input v-model="form.zipcode" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <div class="text-center mb-3">
                    <b-button type="submit" variant="primary" size="lg" :disabled="isSubmited" block>
                        Registreren
                        <b-spinner small v-if="isSubmited" class="align-middle" />
                    </b-button>
                </div>

                <div class="mb-3 text-center">
                    <small>
                        <p>Deze site wordt beschermd door reCAPTCHA. Het <a href="https://policies.google.com/privacy" target="_blank" class="text-primary">privacybeleid</a> en de <a href="https://policies.google.com/terms" target="_blank" class="text-primary">voorwaarden</a> van Google zijn van toepassing.</p>
                        <p>Als je op 'Registreren' klikt, accepteer je onze <a href="https://www.tixgo.nl/algemene-voorwaarden/" target="_blank" class="text-primary">algemene voorwaarden</a> en bevestig je dat je achttien (18) jaar of ouder bent of toestemming hebt van je ouders of verzorgers.</p>
                    </small>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            login_to: {
                type: String,
                default: '/account/login',
            },
            verify_to: {
                type: [Boolean, String],
                default: '/account/verify',
            },
            login: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                error: '',
                isSubmited: false,
                form: {
                    goto: '',
                    email: '',
                    password: '',
                    firstname: '',
                    lastname: '',
                    country: 'nl',
                    zipcode: '',
                },
                countryOptions: [
                    { value: 'be', text: 'België' },
                    { value: 'de', text: 'Duitsland' },
                    { value: 'nl', text: 'Nederland' },
                ]
            }
        },
        methods: {
            async register() {

                this.error = '';
                this.isSubmited = true;

                let check = await this.$refs['form1'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });

                    this.isSubmited = false;
                    return;
                }

                this.form.goto = window.location.host + this.verify_to;

                this.$axios.post("https://api.tixgo.nl/customer/create", this.form)
                    .then( response => {
                        if (response.data.status == 'success') {
                            if (this.login) {
                                this.$axios.post("https://api.tixgo.nl/customer/login", this.form)
                                    .then( response => {
                                        if (response.data.status == 'success') {
                                            this.$cookies.set('token', response.data.session.token);
                                            localStorage.setItem('type', response.data.session.type);
                                            
                                            this.$axios.defaults.headers.common = {
                                                'Authorization': `Bearer ${this.$cookies.get('token')}`
                                            };
                                            
                                            if (this.verify_to) {
                                                this.$router.push(this.verify_to);
                                            } else {
                                                window.location.reload();
                                            }
                                        } else {
                                            this.isSubmited = false;
                                        }
                                    }
                                );
                            } else {
                                if (this.verify_to) {
                                    this.$router.push(this.verify_to);
                                } else {
                                    window.location.reload();
                                }
                            }
                        } else {
                            this.isSubmited = false;
                            this.error = 'Je e-mailadres is al geregistreerd. Probeer in te loggen of vraag een nieuw wachtwoord aan.';
                        }
                    }
                );
            }
        }
    });
</script>